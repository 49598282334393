<!-- System: STA
    Purpose: In update projects modal user can update the 
            current project.
-->
<template>
  <div>
    <!-- Update Project Modal -->
    <div>
      <v-row justify="center">
        <v-dialog
          max-width="1150px"
          v-model="update_modal"
          @click:outside="onHidden()"
          @keydown.esc="onHidden()"
        >
          <!-- Update Project Card -->
          <v-card class="white-bg">
            <v-card-title>
              <span class="headline">Update Project</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-container>
              <!-- Create Project Form -->
              <v-form
                @submit.prevent="onSubmit"
                autocomplete="off"
                ref="updateProjectForm"
                id="updateProjectForm"
                v-model="valid"
              >
                <v-row>
                  <!-- Project Image -->
                  <v-col cols="12" md="4">
                    <div
                      class="file-upload ml-3 mt-12"
                      v-show="!editMembersOnly"
                    >
                      <div class="text-center cursor-poniter" v-if="!image">
                        <label class="fileContainer">
                          <div>
                            <img
                              src="../../assets/images/dashboard_icons/cloud_icon.svg"
                              height="100"
                              width="100"
                            />
                          </div>
                          <div>
                            Upload image of <b>Project logo</b> <br /><small
                              >max size 1 MB of dimensions 174 x 75</small
                            >
                          </div>
                          <input
                            @change="onFileChange"
                            accept="image/png, image/jpg, image/jpeg"
                            name="file_name"
                            type="file"
                          />
                        </label>
                      </div>

                      <div class="text-center" v-else>
                        <imageCroper
                          :cropHeight="75"
                          :cropWidth="174"
                          :imgSrc="image"
                          :imgStyle="{ width: 'auto', height: 'auto' }"
                          :resizeable="true"
                          @croped_file="attach_crop_file($event)"
                          alt="Project Image"
                          v-if="!cropped_file_url && is_cropper"
                        />
                        <img
                          :src="image"
                          class="mb-3 img-thumbnail"
                          v-if="
                            !cropped_file_url &&
                            !is_cropper &&
                            project_details.logo
                          "
                          height="120"
                          width="150"
                        />
                        <img
                          alt="Thumbnail"
                          class="mb-3 img-thumbnail"
                          fluid
                          src="../../assets/images/no-member.svg"
                          thumbnail
                          v-else-if="!cropped_file_url && !is_cropper && !image"
                          height="150"
                          width="170"
                        />
                        <img
                          :src="cropped_file_url"
                          class="mb-3 img-thumbnail"
                          v-if="cropped_file_url && is_cropper"
                          height="150"
                          width="170"
                        />
                        <br />
                        <br />
                        <v-btn
                          @click="removeImage"
                          small
                          color="error"
                          type="button"
                          class="mb-3 mt-2"
                          v-if="cropped_file_url || !cropped_file_url"
                        >
                          Remove image
                        </v-btn>
                      </div>
                    </div>

                    <div class="my-create-project mt-12 ml-3">
                      <!-- Project Name -->
                      <v-text-field
                        class="mt-n2"
                        id="exampleInput1"
                        name="project_name"
                        label="Project name"
                        outlined
                        clearable
                        type="text"
                        v-model="project_name"
                        :rules="projectNameRules"
                      >
                      </v-text-field>
                      <!-- Project Start Date -->
                      <div class="mt-6 ml-0">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_date"
                              label="Date"
                              hint="MM/DD/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              @blur="start_date = parseDate(start_date)"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="start_date"
                            no-title
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <!-- Project Invite Member By Email Component -->
                      <div class="mt-12 mr-5">
                        <ProjectInviteMember />
                      </div>
                      <!-- Update Project Button -->
                      <v-btn
                        :disabled="!valid || updateLoader"
                        class="create-project-button mt-10 mb-2"
                        @click="onSubmit"
                        ref="submit"
                        type="submit"
                        color="primary"
                        primary
                      >
                        <v-progress-circular
                          indeterminate
                          v-if="updateLoader"
                          size="20"
                        >
                        </v-progress-circular>
                        {{ editMembersOnly ? "Add Members" : "Update Project" }}
                      </v-btn>
                    </div>
                  </v-col>
                  <!-- Add Existing Members Area -->
                  <v-col
                    cols="12"
                    md="8"
                    style="max-height: 500px; overflow: scroll"
                  >
                    <div>
                      <v-row class="d-flex mr-auto">
                        <v-col cols="12" lg="6">
                          <h6 class="title pt-3 ml-n16 mt-2">
                            Add Existing Member
                          </h6>
                        </v-col>
                        <v-spacer> </v-spacer>
                        <v-col cols="12" lg="6">
                          <!-- Search Bar -->
                          <v-text-field
                            outlined
                            dense
                            class="mt-3"
                            autocomplete="off"
                            v-model="member_search"
                            counter="25"
                            label="Search Member"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <!-- Email Chip For Members Invited By Email -->
                      <div
                        class="m-0 row selected-members mb-3"
                        v-if="
                          inviteMembersFormData.emails &&
                          inviteMembersFormData.emails.length
                        "
                      >
                        <v-chip
                          class="ma-2"
                          close
                          color="green"
                          outlined
                          @click:close="removeSelected(key)"
                          :key="selected.id"
                          @click="removeSelected(key)"
                          v-for="(
                            selected, key
                          ) in inviteMembersFormData.emails"
                        >
                          {{ selected }}
                        </v-chip>
                      </div>
                      <!-- Existing Member Cards -->
                      <v-row
                        class="m-0 ml-4 pro-members-wrapper"
                        :class="{ scroll: search_members.length > 6 }"
                        v-if="search_members.length > 0"
                      >
                        <div
                          :key="index"
                          @click="moveToSelected(member)"
                          class="my-member mr-2 ml-2 mb-3"
                          v-for="(member, index) in search_members"
                        >
                          <SingleMember
                            :member="member"
                            :selected_members="project_details.members"
                          />
                        </div>
                      </v-row>
                      <!-- No Member Found Component -->
                      <div class="mb-5" v-else>
                        <LoadingMember v-if="fetchLoader" />
                        <NoMemberFound v-else />
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
      </v-row>
    </div>

    <!-- Dialog Box For Remove Selected Member  -->
    <v-dialog
      v-model="removeFromSelectedModal"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="headline red lighten-2">
          Are you sure?
        </v-card-title>
        <v-card-text class="mt-4">
          <h4>
            You want to delete this member. Removing the member will delete all
            the member data against this project. Archiving will only deactivate
            the member. Following action will also be performed on the member if
            he is not associated with any other projects.
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="removeFromSelectedModal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(removeFromSelectedModal = false), deleteSelected()"
          >
            Delete
          </v-btn>
          <v-btn
            color="warning"
            outlined
            text
            @click="(removeFromSelectedModal = false), archiveSelected()"
          >
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { baseURL } from "../../assets/js/urls";
import _ from "lodash";
import moment from "moment";
import { ProjectNameRules, required } from "@/Constants/Const";

export default {
  name: "UpdateProject",
  components: {
    NoMemberFound: () => import("@/components/includes/errors/NoMemberFound"),
    LoadingMember: () => import("@/components/includes/errors/LoadingMember"),
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
    ProjectInviteMember: () =>
      import("@/components/projects/ProjectInviteMember"),
    SingleMember: () => import("@/components/projects/SingleMember"),
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      fetchLoader: false,
      destroyEmp: false,
      update_modal: false,
      updateLoader: false,
      projectNameRules: ProjectNameRules,
      selectedOption: "",
      valid: true,
      removeFromSelectedModal: false,
      size: "md",
      project_details: [],
      filename: null,
      picturePath: "",
      kicked_out_members: [],
      image: "",
      member_search: "",
      member_search_array: [],
      is_cropped: false,
      is_cropper: false,
      cropped_file_url: "",
      remove_image: false,
      editMembersOnly: false,
    };
  },
  props: ["project_id"],

  mounted() {
    this.kicked_out_members = [];
    this.editMembersOnly = false;
    this.$root.$on("update_project_modal_edit_members", (project_id) => {
      this.kicked_out_members = [];
      this.image = "";
      if (project_id) this.getProjectDetails(project_id);
      this.update_modal = true;
    });
    this.member_search_array = this.selectOptions;
  },

  destroyed() {
    this.$store.commit("custom/inviteMembersFormData", {});
  },

  watch: {
    update_modal() {
      if (this.update_modal == false) {
        this.project_details.start_date = null;
        this.member_search = "";
      }
    },
    selectOptions(val) {
      this.member_search_array = val;
    },
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  computed: {
    ...mapState("custom", [
      "employer_employees",
      "selected_company",
      "inviteMembersFormData",
      "single_project",
    ]),
    sortedMembers() {
      let assignedMembers = [];
      let unAssignedMembers = [];
      this.employer_employees.forEach((member) => {
        if (this.members) {
          let mem = this.members.find((m) => m.id === member.id);
          if (mem) {
            assignedMembers.push(member);
          } else {
            unAssignedMembers.push(member);
          }
        }
      });
      return unAssignedMembers.concat(assignedMembers);
    },
    /**
     * Return searched members list
     */
    search_members() {
      let members = [];
      this.member_search = this.member_search.trimStart();
      if (this.sortedMembers) {
        this.sortedMembers.forEach((member) => {
          if (
            member.name &&
            member.name.toLowerCase().includes(this.member_search.toLowerCase())
          ) {
            members.push(member);
          }
        });
        if (members.length > 6) {
          this.size = "xl";
        } else if (members.length > 0) {
          this.size = "lg";
        }
      }
      return members.reverse();
    },

    selectOptions() {
      let selectOptions_arr = Object.assign([], this.employer_employees);
      if (this.members) {
        this.members.forEach((member) => {
          selectOptions_arr.forEach((select, val) => {
            if (select.id === member.id) {
              selectOptions_arr.splice(val, 1);
            }
          });
        });
      }
      return selectOptions_arr;
    },
    project_name: {
      get() {
        if (this.project_details.logo)
          this.image = baseURL.API_URL + "storage/" + this.project_details.logo;
        else this.image = "";
        return this.project_details.project_name;
      },
      set(value) {
        this.project_details.project_name = value;
      },
    },
    start_date: {
      get() {
        return this.project_details.start_date;
      },
      set(value) {
        this.project_details.start_date = value;
      },
    },
    members: {
      get() {
        return this.project_details.members;
      },
      set(value) {
        this.project_details.members.push(value);
      },
    },
  },

  methods: {
    /**
     * This function is resposible for Formating
     * date pattern
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    /**
     * This function is resposible for Fetching
     * the data of currently selected project
     */
    getProjectDetails: _.debounce(function (projectId) {
      let data = {
        company_id: this.$route.params.id,
        project_id: projectId,
      };
      this.fetchLoader = true;
      this.$store
        .dispatch("custom/get_project_data", data)
        .then((response) => {
          this.fetchLoader = false;
          this.$store.commit(
            "custom/set_employer_employees",
            response.data.data.employer_all_employees
          );
          this.project_details = response.data.data.project_details;
          setTimeout(() => {
            this.$nextTick(() => {
              this.$store.commit("custom/toggle_loader", false);
            });
          }, 500);
          this.update_modal = true;
        })
        .catch(() => {
          this.fetchLoader = false;
          this.$store.commit("custom/toggle_loader", false);
        });
    }, 100),
    /**
     * This function is resposible for Updating
     * the current project by submiiting the form data
     */
    onSubmit(e) {
      e.preventDefault();
      let formData = new FormData(document.getElementById("updateProjectForm"));
      if (this.$store.state.custom.projects_list) {
        let index = this.$store.state.custom.projects_list.active.findIndex(
          (option) =>
            option.id !== this.project_details.id &&
            option.project_name === this.project_name
        );
        if (index > -1) {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Please enter unique project name its already exists",
            snackbarColor: "red",
          });
          return false;
        }
        let archived_index = this.$store.state.custom.projects_list.archived.findIndex(
          (option) =>
            option.id !== this.project_details.id &&
            option.project_name === this.project_name
        );
        if (archived_index > -1) {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Please enter unique project name its already exists",
            snackbarColor: "red",
          });
          return false;
        }
      }
      let members = this.project_details.members.map((value) => value.id);
      formData.append("project_id", this.project_details.id);
      formData.append("company_id", this.$route.params.id);
      if (this.inviteMembersFormData.emails) {
        let inviteMemDesignations = this.cleanArray(
          this.inviteMembersFormData.designations
        );
        this.cleanArray(this.inviteMembersFormData.emails).forEach(
          (member, index) => {
            formData.append("emails[]", member);
            formData.append("designations[]", inviteMemDesignations[index]);
          }
        );
      }
      if (members) {
        members.forEach((member_id) => {
          if (this.kicked_out_members[member_id]) {
            this.kicked_out_members.splice(member_id, 1);
          }
          formData.append("member_ids[]", member_id);
        });
      }
      if (this.kicked_out_members) {
        Object.keys(this.kicked_out_members).forEach((key) => {
          formData.append(
            "kicked_out_members[" + key + "]",
            this.kicked_out_members[key]
          );
        });
      } else {
        formData.append("kicked_out_members", this.kicked_out_members);
      }
      formData.append("logo", this.picturePath);
      formData.append("remove_image", this.remove_image);
      this.updateLoader = true;
      this.$store
        .dispatch("custom/updateProject", formData)
        .then((response) => {
          this.updateLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          this.update_modal = false;
          if (response.data.error === false) {
            this.$store.commit("custom/inviteMembersFormData", {});
            this.$store.dispatch("custom/getEmployeeEmployers", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/getProjects", {
              company_id: this.$route.params.id,
            });
            this.$root.$emit("snack_bar", {
              show: true,
              message: "High Five!, The project has been created.,",
              snackbarColor: "green",
            });
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
          }
        })
        .catch((error) => {
          this.updateLoader = false;
          console.log(error);
          if (error.response.data.error === true) {
            this.$store.commit("custom/toggle_loader", false);
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
    /**
     * This function is resposible for moving the
     * user card into selected member list
     */
    moveToSelected(option) {
      if (
        this.project_details.members.find((member) => member.id === option.id)
      ) {
        this.removeFromSelected(option);
      } else {
        this.project_details.members.push(option);
      }
    },
    /**
     * This function is resposible for removing the
     * member chip from invite new member list
     */
    removeSelected(option) {
      this.$store.commit("custom/splice_inviteMembersFormData", option);
    },
    /**
     * This function is resposible for deleteing the
     * user card from member list
     */
    deleteSelected() {
      let option = this.selectedOption;
      this.project_details.members.forEach((value, index) => {
        if (value.id === option.id) {
          this.project_details.members.splice(index, 1);
          this.sendDelete(option.id);
        }
      });
      this.kicked_out_members[option.id] = 0;
    },
    /**
     * This function is resposible for archived the
     * user card from member list
     */
    archiveSelected() {
      let option = this.selectedOption;
      this.project_details.members.forEach((value, index) => {
        if (value.id === option.id) {
          this.project_details.members.splice(index, 1);
          this.sendArchive(option.id);
        }
      });
      this.kicked_out_members[option.id] = 1;
    },
    /**
     * This function is resposible for removing the
     * user card from member list
     */
    removeFromSelected(option) {
      this.selectedOption = option;
      this.removeFromSelectedModal = true;
    },

    sendDelete(memberId) {
      this.$eventBus.$emit("send-delete", memberId);
    },

    sendArchive(memberId) {
      this.$eventBus.$emit("send-archive", memberId);
    },
    /**
     * Below These function is resposible for Image
     * uploading , croping and removing
     */
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },

    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        return;
      }
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      this.picturePath = file;
      this.remove_image = true;
      reader.readAsDataURL(file);
      this.is_cropper = true;
    },

    removeImage: function () {
      this.image = "";
      this.picturePath = "";
      this.cropped_file_url = "";
      this.is_cropped = false;
      this.remove_image = true;
    },

    cleanArray(actual) {
      let newArray = [];
      for (var i = 0; i < actual.length; i++) {
        newArray.push(actual[i]);
      }
      return newArray;
    },

    attach_crop_file(file_url) {
      let file_path = this.dataURLtoFile(file_url, "project_logo");
      this.cropped_file_url = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    /**
     * This function is resposible for clearing
     * form data when modal is close
     */
    onHidden() {
      this.$store.commit("custom/inviteMembersFormData", {});
      this.removeImage();
      this.member_search = "";
      this.$refs.updateProjectForm.reset();
      this.update_modal = false;
      this.$store.commit("custom/set_employer_employees", []);
    },
  },
};
</script>

<style scoped>
.error-msg {
  color: tomato;
  font-size: 12px;
}
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}

.file-upload {
  border: dashed 2px #cbdaf2;
  cursor: pointer !important;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer !important;
  font-size: 11px;
  height: 92px;
  border-radius: 3px;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer [type="file"] {
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  cursor: pointer;
}

.fileContainer img {
  height: 32px;
  width: 32px;
}
</style>
